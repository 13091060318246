/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    briefcase: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.5 1A1.5 1.5 0 005 2.5V3H1.5A1.5 1.5 0 000 4.5v8A1.5 1.5 0 001.5 14h13a1.5 1.5 0 001.5-1.5v-8A1.5 1.5 0 0014.5 3H11v-.5A1.5 1.5 0 009.5 1zm0 1h3a.5.5 0 01.5.5V3H6v-.5a.5.5 0 01.5-.5m1.886 6.914L15 7.151V12.5a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5V7.15l6.614 1.764a1.5 1.5 0 00.772 0M1.5 4h13a.5.5 0 01.5.5v1.616L8.129 7.948a.5.5 0 01-.258 0L1 6.116V4.5a.5.5 0 01.5-.5"/>',
    },
});
